
import { Component, Prop, Vue } from 'vue-property-decorator'
import { ElForm } from 'element-ui/types/form'
import UploadFile from '@/components/uploadFile/Index.vue'
import { HandleHandoverMatter } from '@/types/handover'
import { FileInfo } from '@/types/common'

@Component({
  components: { UploadFile },
  filters: {
    previewList: function (value: FileInfo[]) {
      return value.map((item: { filePrefix: string; fileUrl: string }) => {
        return item.filePrefix + item.fileUrl
      })
    }
  }
})

export default class HandoverMatterHandle extends Vue {
  @Prop({ default: false }) handleShowDialog!: boolean
  @Prop({ default: '' }) issueId!: string
  private formInfo = this.formInfoBak
  get formInfoBak () {
    return {
      /** 项目ID */
      issueId: '',
      dealBak: '',
      /** 地址 */
      dealFileList: []
    }
  }

  private rules = {
    dealBak: [
      { required: true, message: '请输入处理说明', trigger: 'change' }
    ]
  }

  private submitShow = false

  onSubmit () {
    this.formInfo.issueId = this.issueId
    ;(this.$refs.formInfo as ElForm).validate(valid => {
      if (valid) {
        this.submitShow = true
        this.$axios.post(this.$apis.handover.dealTransferIssue, this.formInfo).then(() => {
          this.$message.success('保存成功')
          this.closeDialog()
          this.$emit('success')
        }).finally(() => {
          this.submitShow = false
        })
      }
    })
  }

  // 文件上传成功返回
  onSuccess (file: FileInfo) {
    ;(this.formInfo as HandleHandoverMatter).dealFileList.push(file)
  }

  imgRemove (index: number) {
    this.formInfo.dealFileList.splice(index, 1)
  }

  closeDialog () {
    this.formInfo = this.formInfoBak
    this.$nextTick(() => {
      (this.$refs.formInfo as ElForm).clearValidate()
    })
    this.$emit('update:handleShowDialog', false)
  }
}
