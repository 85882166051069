import { render, staticRenderFns } from "./HandoverMatter.vue?vue&type=template&id=b7ad4f72&scoped=true"
import script from "./HandoverMatter.vue?vue&type=script&lang=ts"
export * from "./HandoverMatter.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b7ad4f72",
  null
  
)

export default component.exports